
import { computed, defineComponent, onMounted, ref } from "vue";
import GuestLayout from "@/layout/GuestLayout.vue";
import Question from "@/components/Pages/TheoryQuestions/Question.vue";
import { useStore } from "vuex";
import TheoryQuestions from "@/views/Guest/TheoryQuestions.vue";
import { useRouter } from "vue-router";
import BackButton from "@/components/Pages/TheoryQuestions/BackButton.vue";

export default defineComponent({
  name: "GuestTheory",
  components: { BackButton, TheoryQuestions, Question, GuestLayout },
  setup() {
    const store = useStore();
    const questionCount = ref(0);
    const router = useRouter();

    const findRandomTheoryQuestion = async () => {
      await store.dispatch("theory-questions/guest/findOne", {
        resource: "/theory-questions/random",
      });
    };

    const reset = async () => {
      await store.dispatch("theory-questions/guest/reset");
    };

    const randomTheoryQuestion = computed(() => store.getters["theory-questions/guest/getDataItem"]);
    const randomTheoryQuestionLoading = computed(() => store.getters["theory-questions/guest/getIsLoading"]);

    onMounted(() => {
      goToNext();
    });

    const goToNext = () => {
      reset();
      questionCount.value = questionCount.value + 1;

      if (questionCount.value > 5) {
        router.push({ name: "RegisterStart" });
      } else {
        findRandomTheoryQuestion();
      }
    };

    const goBack = () => {
      router.push({ name: "GuestHome" });
    };

    return {
      randomTheoryQuestion,
      randomTheoryQuestionLoading,
      goToNext,
      goBack,
    };
  },
});
